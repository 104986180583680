@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-start; */
  align-items: center;
  padding: 0 20px 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  /* fill */
background-color:#fffbf2;

  /* shadows */
  /* box-shadow: var(--boxShadowLight); */

  border-bottom: 2px solid #000000;
  @media (--viewportLarge) {
    /* padding: 0 24px 0 0; */
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  & > img {
    height: 40px;
  }
}
.logoLink:hover {
  border-bottom: 3px solid var(--marketplaceColor);
  border-radius: 0;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  /* border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100); */
  margin: 13px 0;
  padding-left: 10px;
  /* border-left-style: solid;
  border-left-width: 4px;
  border-left-color: #000; */
  margin: 0;
  @media (--viewportLarge) {
    /* padding-left: 10px; */
  }
}
/* .authSearchLink {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
  margin: 0;
} */

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  /* padding-left: 24px; */
  /* height: var(--topbarHeightDesktop); */

  @media (--viewportLarge) {
    /* padding-left: 34px; */
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: #fc334d;
    border-bottom: 4px solid #fc334d;
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.blogLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);
  font-family: 'NeueHaasGroteskText Pro Md';
  font-weight: 500;
  flex-shrink: 0;
  height: 100%;
  /* padding: 0 24px 0 12px; */
  padding: 0 12px;

  &:hover {
    /* color: #fc334d;
    border-bottom: 4px solid #fc334d; */
    border-bottom: 4px solid #000000;
    color: #000;
    border-radius: 0;
    text-decoration: none;
    /* color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor); */
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 0 16px 0 16px;
  }
  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  /* margin: 28px 0; */
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: #fc334d;
    border-bottom: 4px solid #fc334d;
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}
.authenticatedLink {
  margin: 28px 0;
}
.BlogauthenticatedLink {
  &:hover {
    color: #fc334d;
    border-bottom: 4px solid #fc334d;
  }
}
.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  /* padding: 0 12px 0 12px; */
  padding: 0 0 0 12px;

  &:hover {
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
  & > div {
    background-image: none !important;
    background-color: #fc334d !important;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  /* border-left: 1px solid black; */
  flex-shrink: 0;
  height: 100%;
  /* padding: 0 12px 0 12px; */
  padding: 0 12px;
  color: var(--colorGrey700);
  font-family: 'NeueHaasGroteskText Pro Md';
  font-weight: 500;
  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 0 16px 0 16px;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  /* padding: 0 12px 0 12px; */
  /* padding: 0 36px 0 16px; */
  padding: 0 12px;
  color: var(--colorGrey700);
  font-family: 'NeueHaasGroteskText Pro Md';
  font-weight: 500;
  /* font-weight:500; */

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 0 16px 0 16px;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 28px 0;
  /* margin: 40px 0; */

  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}
.searchWithIcon {
  display: flex;
  align-items: center;
  padding-left: 10px;
  &:hover {
    text-decoration: none;
  }
  & > svg {
    /* fill: #fc334d; */
    margin-top: 4px;
  }
}
.searchTitle {
  padding-left: 8px;
  color: RGBA(74, 74, 79, 1);
  font-size: 14px;
  line-height: 22px;
  font-family: 'NeueHaasGroteskText Pro Md';
  font-weight: 500;
  margin-top:4px;
  &:hover {
    color: #000;
  }
}
.authenticatedSearchBar {
  margin: 0;
  padding-left: 10px;
  /* border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100); */
}
.safariMargin{
  margin-top:2px;
}
.favouritesLink{
   border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  /* line-height: 18px; */
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: #fc334d;
    border-bottom: 4px solid #fc334d;
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    /* line-height: 16px; */
    /* margin: 0; */
  }
}