@import '../../styles/customMediaQueries.css';

.root {
  display: block;
  flex-shrink: 0;
  padding: 0;
  height: 100%;
  margin: auto;
  padding: 0 23px;
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    /* padding: 0 24px; */
  }

  @media (--viewportLarge) {
    /* padding: 0 36px; */
  }
  @media(max-width:767px){
    padding: 10px 23px;
    height:inherit;
  }
}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  /* height: 24px; */
  /* height: 30px; */

  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
  margin: 15px 0;

  @media (--viewportMedium) {
    /* margin: 24px 0; */
    /* margin: 30px 0; */
  }
  @media(max-width:767px){
    margin:0;
    width:140px;
  }
}
.authenticatedLogo {
 
}
