@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  /* border-radius: var(--borderRadiusMedium); */
  border-radius: 50px !important;
  overflow: hidden;
  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      /* box-shadow: var(--boxShadowListingCard); */
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  @media (max-width: 575px) {
    height: auto;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}
.priceForsearch{
   display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin: 4px 0 0;
}
.priceValue {
  /* Font */
  composes: textSmall from global;
  /* font-weight: var(--fontWeightSemiBold); */
  font-weight: 400;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  line-height:20px;
  /* color: var(--colorGrey600); */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  & > span{
    margin-left:5px;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  /* font-weight: var(--fontWeightBold); */
  /* color: var(--colorGrey700); */
  font-weight:400;
  margin: 0;
  color: #000;
  line-height: 20px;
  text-transform: capitalize;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  /* font-weight: var(--fontWeightBold); */
  /* color: var(--colorGrey700); */
  color: #000;
  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.priceandheart,.locationAndHeart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.locationAndHeart{
  &>div{
    margin-bottom: 0;
    font-size: 14px;
      /* font-weight: 400; */
      font-weight:700;
      color: #000;
  }
}
.favIconDiv {
  border: 0;
  cursor: pointer;
  width: max-content;
}
.favDisabled {
  & > svg {
    & > path {
      fill: var(--colorGrey100);
    }
  }
}
.vendorName {
  composes: marketplaceTinyFontStyles from global;
  /* font-weight: var(--fontWeightBold); */
  font-weight:400;
  /* color: var(--colorGrey700); */
  /* text-decoration: underline; */
  text-transform: capitalize;
  padding-top: 4px;
  padding-bottom: 4px;
  /* padding-left:5px; */
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  & .pinIcon{
    font-size:13px;
  }
 & > span:nth-child(2){
   margin-left:7px;
 } 
}
/* .PriceTitleWrap{
  display: inline-flex;
    align-items: center;
} */
.priceDiv{}
.priceAmnt{
  color:#64ae27;
  font-size: 14px;
 }
 .IncludesDiv{
  font-size:14px;
  text-transform: capitalize;
 }