@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
  @media (max-width: 767px) {
    margin: 24px 0;
    width: 100%;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 auto 56px auto;
  padding: 64px 24px 0 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 1920px) {
    max-width: 1440px !important;
    margin: 0 auto !important;
  }

  @media (--viewportLarge) {
    max-width: unset;
    /* width: min(calc(var(--contentMaxWidth) + 72px), 100vw); */
    width: 100%;
    padding: 30px 70px 0 217px;
    /* padding: 217px from both sides for logo */
    margin: 0 auto 117px auto;
  }
  @media (max-width: 1199px) {
    width: 100% !important;
    max-width: none !important;
    padding: 25px 15px !important;
    gap: 0 15px;
  }
  & > div:nth-child(1) {
    @media (max-width: 1199px) {
      flex: 0 0 48% !important;
      max-width: 48% !important;
    }
    @media (max-width: 1023px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  & > div:nth-child(2) {
    @media (max-width: 1199px) {
      flex: 0 0 48% !important;
      max-width: 48% !important;
      margin-left: 20px !important;
    }
    @media (max-width: 767px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
    }
  }
  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    margin: 0 auto 30px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
  /* @media(max-width:1023px){
    display: block;
  } */
  & > div {
    & > div {
      & > div {
        & > div {
          @media (max-width: 1199px) {
            width: 90% !important;
          }
        }
      }
    }
  }
}

.orderPanelTitle {
  /* Font */
  /* font-weight: var(--fontWeightBold); */
  /* font-weight:400; */
  font-weight: 700;
  color: #000;
  /* color: var(--colorGrey600); */
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media(max-width:1023px){
    display: none;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 26px;
    margin-top: 15px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    padding: 0;
    /* margin-bottom: 40px; */
  }
  @media (max-width: 767px) {
    padding: 0 !important;
    margin-bottom: 10px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
  & > div {
    margin-bottom: 5px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
  & > div {
    & > div {
      & > a {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0;
    margin-top: 15px;
  }
}
.sectionReviews {
  & > ul {
    & > li {
      & > div {
        align-items: center;
        @media (max-width: 767px) {
          align-items: flex-start;
        }
        & > div {
          @media (max-width: 767px) {
            margin-right: 15px;
          }
          & > p {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
  }
}
.sectionAuthor {
  & > div {
    & > div {
      & > a {
        margin-right: 22px;
        @media (max-width: 767px) {
          margin-right: 15px;
        }
      }
    }
  }
}
.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  /* justify-content: space-between; */
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);
  gap: 5px 0;
  flex-direction: column;
  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: inherit;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
  @media (max-width: 1023px) {
    max-width: 100%;
    max-height: 100%;
  }
  & > div {
    @media (max-width: 1023px) {
      max-width: 100% !important;
    }
    & > img {
      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
  & > div {
    & > div {
      & > div {
        & > div {
          @media (max-width: 480px) {
            /* min-height:230px !important; */
            /* height:200px !important; */
            /* height:190px !important; */
          }
          & > div {
            & > div {
              & > div {
                & > div {
                  @media (max-width: 575px) {
                    top: -20px !important;
                  }
                }
              }
            }
          }
          & > div {
            /* @media(max-width:480px){
            height:190px !important;
          } */
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    margin: 0;
  }
  & > div {
    & > div {
      & > div {
        & > div {
          & > div {
            /* height: calc(100vh - 140px) !important; */
          }
        }
      }
    }
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
  @media (max-width: 767px) {
    margin: 0 0 10px 0;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}
.upperAddress {
  margin-bottom: 15px;
  @media (max-width: 768px) {
    margin-left: 28px;
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.ManualAddressTitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
.mannualAddressOuter {
  @media (max-width: 768px) {
    margin-left: 24px;
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.mannualAddressInner {
  /* margin-left: 12px; */
  @media (max-width: 768px) {
    margin-left: 14px;
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.RulesDiv {
  @media (max-width: 1023px) {
    margin-top: 20px;
  }
}
.rulesHead {
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;
}
.listStyle {
  list-style: none;
}
.ListingPageWrap {
  & > div {
    @media (max-width: 767px) {
      width: 100%;
    }
    & > div {
      @media (max-width: 767px) {
        display: block !important;
        /* width:100; */
      }
      & > main {
        @media (max-width: 767px) {
          display: inherit;
          width: 100%;
        }
      }
    }
  }
}
.ListingpageMain {
  display: block !important;
  & > main {
    display: block;
  }
}
.locationAndFavDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.favbtn {
  cursor: pointer;
  border: 0;
}

.mobilelocationDIV {
  display: none;
  @media (max-width: 1023px) {
    display: flex;
    align-items: baseline;
    margin-bottom:5px;
  }
  & .pinIcon {
    font-size: 13px;
  }
  & .businessName {
    color: #000;
    font-family: var(--bodyfontfamily);
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 0 7px;
    text-transform: capitalize;
  }
}
.MobiledurationMainDiv{
  display:none;
  @media(max-width:1023px){
    display: flex;
    align-items:baseline;
    margin-bottom: 5px;
  }
  & .clockIcon{
    font-size: 13px;
    margin-right:7px;
  }
  & .timeDurationHours, .timeDuration{
    color: #000;
    font-size: 16px;
    line-height: 20px;
  }
  & .timeDurationHours{
    margin-right:5px;
  }
}
.mobileIncludesDiv{
  display:none;
  @media(max-width:1023px){
    display: block;
  }
  & .IncludesDiv{
    text-transform: capitalize;
    font-size: 16px;
    line-height: 22px;
    font-family: var(--bodyfontfamily) !important;
    font-weight: 400;
    margin-bottom: 25px;
    color: #000;
  }
}
.mobilebookingMessage{
  display:none;
  @media(max-width:1023px){
    display: block;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    text-decoration: underline;
  }
}