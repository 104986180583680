.root {
}

.inputError {
  border: var(--borderErrorField);
}
.outerDiv {
  display: flex;
}
.innerRemoteDiv {
  width: 20%;
  margin: auto;
  text-transform: capitalize;
}
