@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  background-color:#fffbf2 !important;
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    /* background-color: var(--colorWhite); */
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (max-width: 1023px) {
    padding: 80px 20px 30px !important;
  }
  & > button {
    @media (max-width: 767px) {
      position: absolute;
    }
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
  @media (max-width: 1023px) {
    margin-top: 0;
    padding: 0;
  }
}

.heading {
  /* Font */
  /* color: var(--colorGrey700); */

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
  @media (max-width: 1023px) {
    font-size: 40px !important;
    line-height: 45px !important;
  }
  @media (max-width: 767px) {
    font-size: 22px !important;
    line-height: 35px !important;
  }
}

.price {
  composes: h4 from global;
  /* font-weight: var(--fontWeightBold); */
  font-weight:400;
  color:#64ae27;
  margin: 0;
  font-size:16px;
  line-height:20px;
  font-family:var(--bodyfontfamily) !important;
  @media (--viewportMedium) {
    /* margin: 10px 0; */
    margin: 0 0 15px 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    /* display: block; */
    margin-bottom: 0; 
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
  &>div:first-child{
    &>label{
      font-weight: 400;
      font-size: 14px;
    }
  }
  & > div {
    font-weight: 300;
    & > div {
      & > div {
        & > div {
          & > div {
            & > div:nth-child(2) {
              @media (max-width: 1023px) {
                left: 0 !important;
                width: 100%;
                top: 50px !important;
              }
              & > div {
                background-color: #fc334d !important;
                width: 312px !important;
                /* & > div{
                  & > div{
                    & > div:nth-child(2){
                      & > div:nth-child(2){
                        & > div{
                          & > div:nth-child(2){
                            & > div{
                              & > table{
                                & > tbody{
                                  & > tr:nth-child(5){
                                    & > td{
                                      background-color: #fc334d !important;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } */
                @media (max-width: 575px) {
                  width: 100% !important;
                }
                & > div {
                  & > div {
                    & > div:nth-child(2) {
                      & > div {
                        & > div {
                          @media (max-width: 767px) {
                            left: unset !important;
                          }
                        }
                      }
                    }
                  }
                }
                @media (max-width: 767px) {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1023px) {
    min-height: 270px !important;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color:#fffbf2 !important;
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.bookingMessage {
  /* font-size: 19px; */
  font-size:16px;
  /* font-weight: 400; */
  font-weight: 400;
  /* border-bottom: 2px solid black; */
  text-decoration:underline;
  margin-bottom: 5px;
  display: inline-block;

  color: #000;
     /* color: var(--colorGrey600); */
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.timeDuration {
  font-size: 16px;
  line-height: 20px;
  /* font-weight: 600; */
  color: #000;
}
.durationMainDiv {
  margin-bottom: 12px;
}
.infoTxt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.businessName {
  composes: h4 from global;
  /* font-size: 21px; */
  /* font-weight: var(--fontWeightBold); */
  text-transform: capitalize;
  color: #000;
  margin: 0;
  font-size:16px;
  font-weight:400;
  margin-left:7px;
  font-family: var(--bodyfontfamily);
  @media (--viewportMedium) {
    /* margin: 10px 0; */
    /* margin-top: 10px; */
    padding: 0;
  }
}
.locationDiv{
  display: flex;
  align-items: baseline;
  & .pinIcon{
    font-size: 13px;
  }
}
.clockIcon{
  margin-right:7px;
  font-size: 13px;
}
.Includes{

  & .IncludesDiv{
    text-transform:capitalize;
    font-size:16px;
    line-height:22px;
    font-family:var(--bodyfontfamily) !important;
    font-weight:400;
    margin-bottom:35px;
    color: #000;
  }
}